import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const BlogDetails = () => {

    const { url, cityParam } = useParams();

    const location = useLocation();
    const navigation = useNavigate();

    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://admin.solarvipani.com/blogs");

                const singleblog = response.data.data.find((i) => i.url === url);

                setId(singleblog?.id);
            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };

        fetchData();
    }, []);

    const [state, setState] = useState({});


    const fetchData = async () => {
        try {
            const response = await axios.get(
                `https://admin.solarvipani.com/blog-details/${id}`
            );

            // console.log(response);

            setState(response.data.data);

        } catch (error) {
            toast.error("Error fetching business details: " + error.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        const title = state.title || "your Home";
        document.title = `Solar Panels for ${title} - Solar Vipani`;
    }, [state.title]);


    // User-Quote
    const [formData, setFormData] = useState({
        systemSize: "",
        type: "",
        pageType: "",
        name: "",
        email: "----",
        pinCode: "",
        gclid_field: "",
        phoneNumber: "",
        comment: "",
        state: "----",
        city: "----",
    });

    useEffect(() => {
        let pageType = "";
        if (location.pathname === "/blog/pm-surya-ghar-yojana") {
            // pageType = "pm-surya-ghar-yojana";
            pageType = window.location.href;
        } else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
            // pageType = "cost-of-solar-on-grid-system";
            pageType = window.location.href;
        } else if (location.pathname === `/solar-panel-installer-directory/${cityParam}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/blogs") {
            pageType = window.location.href;
        } else if (location.pathname === `/blogs/${url}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/") {
            // pageType = "Home";
            pageType = window.location.href;
        }

        setFormData({
            ...formData,
            pageType: pageType,
        });
    }, [location.pathname]);

    const handleChangeBook = (e) => {
        const { name, value } = e.target;

        if (name === "phoneNumber") {
            const input = value.replace(/\D/g, "").slice(0, 15);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else if (name === "state") {
            setFormData((prevData) => ({
                ...prevData,
                state: value,
                city: "",
            }));
        } else if (name === "city") {
            setFormData((prevData) => ({
                ...prevData,
                city: value,
            }));
        } else if (name === "pinCode") {
            const input = value.replace(/\D/g, "").slice(0, 6);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/user-quote",
                formData
            );

            if (!response.data.res) {
                toast.error("Quate is not added!");
            } else {
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    state: "",
                    city: "",
                    type: "",
                    systemSize: "",
                    pinCode: "",
                    gclid_field: "",
                    pageType: "",
                    comment: "",
                });

                navigation("/thanks");
            }
        } catch (error) {
            toast.error("Error fetch form data" + error.message);
        }
    };


    return (
        <div className="rooftop_main">
            <div className="container">

                <div className="top_text mb-5">
                    <h1> Solar Panels for {state.title || "your Home"}</h1>
                </div>

                {
                    Object.keys(state).length ? (
                        <>
                            <h1 className="rooftop_title">{state.title}</h1>
                            <div className="rooftop_content">
                                <p className="rooftop_desc mb-4">{state.description}</p>
                                <div
                                    className="rooftop_desc mb-4"
                                    dangerouslySetInnerHTML={{ __html: state.details }}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="error-message">
                                <p className="text-center">Blog details not found.</p>
                            </div>
                        </>
                    )
                }

                <div className="blog-right-side mt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="text mb-3">
                            {/* <h5>Book Consultation Call to get a Quote</h5> */}
                            <h5>Book Free Consultation To Get A Quote</h5>
                        </div>
                        <div className="form_fields row">
                            <div className="col-12 mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    id="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChangeBook}
                                    required
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="phoneNumber" className="form-label">
                                    {/* Phone */}
                                    Mobile No
                                </label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Mobile No"
                                    value={formData.phoneNumber}
                                    onChange={handleChangeBook}
                                    required
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="pinCode" className="form-label">
                                    Pin Code
                                </label>
                                <input
                                    type="number"
                                    name="pinCode"
                                    className="form-control"
                                    id="pinCode"
                                    placeholder="Pin Code"
                                    value={formData.pinCode}
                                    onChange={handleChangeBook}
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="type" className="form-label">
                                        Type of Solar
                                    </label>
                                    <select
                                        className="form-select form-select-lg mb-3"
                                        id="type"
                                        name="type"
                                        required
                                        onChange={handleChangeBook}
                                        value={formData.type}
                                    >
                                        <option value="">Select</option>
                                        <option value="Residential - Independant home">
                                            Residential - Independant home
                                        </option>
                                        <option value="Residential - Apartment/Housing society">
                                            Residential - Apartment/Housing society
                                        </option>
                                        <option value="Commercial/Business">
                                            Commercial/Business
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 mb-3">
                                <label htmlFor="comment" className="form-label">
                                    Comment (optional)
                                </label>
                                <textarea
                                    className="form-control"
                                    name="comment"
                                    id="comment"
                                    placeholder="Comment (optional)"
                                    value={formData.comment}
                                    onChange={handleChangeBook}
                                    rows={3}
                                />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="submit_btn w-100">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default BlogDetails;
