import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const Home = () => {

    const { cityParam, url } = useParams();

    const [formData, setFormData] = useState({
        systemSize: "",
        type: "",
        pageType: "",
        name: "",
        email: "----",
        pinCode: "",
        gclid_field: "",
        phoneNumber: "",
        comment: "",
        state: "----",
        city: "----",
    });

    const location = useLocation();

    const navigation = useNavigate();

    useEffect(() => {
        let pageType = "";
        if (location.pathname === "/blog/pm-surya-ghar-yojana") {
            // pageType = "pm-surya-ghar-yojana";
            pageType = window.location.href;
        } else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
            // pageType = "cost-of-solar-on-grid-system";
            pageType = window.location.href;
        } else if (location.pathname === `/solar-panel-installer-directory/${cityParam}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/blogs") {
            pageType = window.location.href;
        } else if (location.pathname === `/blogs/${url}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/") {
            // pageType = "Home";
            pageType = window.location.href;
            
        }
        setFormData({
            ...formData,
            pageType: pageType,
        });

        function getParam(p) {
            var match = RegExp("[?&]" + p + "=([^&]*)").exec(window.location.search);
            return match && decodeURIComponent(match[1].replace(/\+/g, " "));
        }

        function getExpiryRecord(value) {
            var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

            var expiryDate = new Date().getTime() + expiryPeriod;
            return {
                value: value,
                expiryDate: expiryDate,
            };
        }

        function addGclid() {
            const gclidParam = getParam("gclid");
            const gclidFormFields = ["gclid_field"];
            let gclidRecord = null;

            const gclsrcParam = getParam("gclsrc");
            const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;

            if (gclidParam && isGclsrcValid) {
                gclidRecord = getExpiryRecord(gclidParam);
                localStorage.setItem("gclid", JSON.stringify(gclidRecord));
            }

            const storedGclid = JSON.parse(localStorage.getItem("gclid"));
            const isGclidValid = storedGclid && new Date().getTime() < storedGclid.expiryDate;

            gclidFormFields.forEach(field => {
                const formField = document.getElementById(field);
                if (formField && isGclidValid) {
                    formField.value = storedGclid.value;
                    setFormData(prevState => ({
                        ...prevState,
                        [field]: storedGclid.value
                    }));
                }
            });
        }

        addGclid();
    }, [location.pathname]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "phoneNumber") {
            const input = value.replace(/\D/g, "").slice(0, 15);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else if (name === "state") {
            setFormData((prevData) => ({
                ...prevData,
                state: value,
                city: "",
            }));
        } else if (name === "city") {
            setFormData((prevData) => ({
                ...prevData,
                city: value,
            }));
        } else if (name === "pinCode") {
            const input = value.replace(/\D/g, "").slice(0, 6);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/user-quote",
                formData
            );

            if (!response.data.res) {
                toast.error("Quate is not added!");
            } else {
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    state: "",
                    city: "",
                    type: "",
                    systemSize: "",
                    pinCode: "",
                    gclid_field: "",
                    pageType: "",
                    comment: "",
                });

                navigation("/thanks");
            }
        } catch (error) {
            toast.error("Error fetch form data" + error.message);
        }
    };

    // const selectedStateData = formData.state
    //   ? StateValue[formData.state]?.data
    //   : [];

    return (
        <section className="home_section">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-6">
                        <div className="home-left-side">
                            <img
                                src="img/Family-standing-infront-of-house-fitted-with-solar-panel.jpg"
                                alt="home-img"
                                className="w-100"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home-right-side-content">
                            <h5 className="mb-4">
                                Best time to install Solar Panel System is Now!{" "}
                            </h5>
                            <div className="d-flex home-content">
                                <div>
                                    <img
                                        src="img/Vector-8.png"
                                        alt="Vector-8"
                                        className="me-2"
                                        width={8}
                                        height={13}
                                    />
                                </div>
                                <div>Reduce your electricity bill up to 95%</div>
                            </div>
                            <div className="d-flex home-content">
                                <div>
                                    <img
                                        src="img/Vector-8.png"
                                        alt="Vector-8"
                                        className="me-2"
                                        width={8}
                                        height={13}
                                    />
                                </div>
                                <div>
                                    {" "}
                                    Get subsidy of up to Rs 78,000 per account under PM Surya Ghar
                                    Yojana{" "}
                                </div>
                            </div>
                            <div className="d-flex home-content">
                                <div>
                                    <img
                                        src="img/Vector-8.png"
                                        alt="Vector-8"
                                        className="me-2"
                                        width={8}
                                        height={13}
                                    />
                                </div>
                                <div>
                                    {" "}
                                    Ensure Quick and Hassle-free experience, with our network of
                                    verified local installer partners{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-right-side">
                    <form onSubmit={handleSubmit} name="myForm">
                        <div className="text mb-3">
                            <h5>Book Free Consultation To Get A Quote</h5>
                        </div>
                        <div className="form_fields row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    id="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="phoneNumber" className="form-label">
                                    Mobile No
                                </label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Mobile No"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="pinCode" className="form-label">
                                    Pin Code
                                </label>
                                <input
                                    type="number"
                                    name="pinCode"
                                    className="form-control"
                                    id="pinCode"
                                    placeholder="Pin Code"
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    type="hidden"
                                    id="gclid_field"
                                    name="gclid_field"
                                    value={formData.gclid_field}
                                />
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="type" className="form-label">
                                        Type of Solar
                                    </label>
                                    <select
                                        className="form-select form-select-lg mb-3"
                                        id="type"
                                        name="type"
                                        required
                                        onChange={handleChange}
                                        value={formData.type}
                                    >
                                        <option defaultValue={""}>Type of Solar</option>
                                        <option value="Residential - Independant home">
                                            Residential - Independant home
                                        </option>
                                        <option value="Residential - Apartment/Housing society">
                                            Residential - Apartment/Housing society
                                        </option>
                                        <option value="Commercial/Business">
                                            Commercial/Business
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 mb-3">
                                <label htmlFor="comment" className="form-label">
                                    Comment (optional)
                                </label>
                                <textarea
                                    className="form-control"
                                    name="comment"
                                    id="comment"
                                    placeholder="Comment (optional)"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    rows={3}
                                />
                            </div>
                            <div className="col-12">
                                <button type="submit" className="submit_btn w-100">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="home-faq-section">
                    <div className="home-faq-section-content">
                        <h5>Frequently Asked Questions</h5>
                    </div>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                >
                                    What is the cost of a Solar Panel System ?
                                </button>
                            </h2>
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h5 className="accordian-sub-heading">
                                        Approximate total cost of Solar On Grid System installation:
                                    </h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Size of Solar On Grid System</th>
                                                    <th scope="col">Approximate cost in Rs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1kW</td>
                                                    <td>72k - 77k</td>
                                                </tr>
                                                <tr>
                                                    <td>2kW</td>
                                                    <td>144k - 158k</td>
                                                </tr>
                                                <tr>
                                                    <td>3kW</td>
                                                    <td>216k - 240k</td>
                                                </tr>
                                                <tr>
                                                    <td>4kW</td>
                                                    <td>268k - 296k</td>
                                                </tr>
                                                <tr>
                                                    <td>5kW</td>
                                                    <td>336k -370k</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="according-bottom-text">
                                        For the requirements above 5kW, the total cost per kW is
                                        approximately Rs 60k to Rs 65k
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    What is the size of the Solar System Should I install ?
                                </button>
                            </h2>
                            <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h5 className="accordian-sub-heading">
                                        Decision on the size of Solar Panel System should be based
                                        on average monthly electricity usage
                                    </h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        Average Monthly Electricity Consumption (kWh/units)
                                                    </th>
                                                    <th scope="col">
                                                        Suitable Rooftop Solar Plant Capacity
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0-150</td>
                                                    <td>1kW – 2 kW</td>
                                                </tr>
                                                <tr>
                                                    <td>150-300</td>
                                                    <td>2kW – 3 kWk</td>
                                                </tr>
                                                <tr>
                                                    <td>&gt;300</td>
                                                    <td>Above 3 kW</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="according-bottom-text">
                                        1kW of solar panel system generates approximately 90-110
                                        kWh/units of electricity per month
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Checklist to install Solar Panel System
                                </button>
                            </h2>
                            <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h5 className="accordian-sub-heading">
                                        Following both the requirement should be met:
                                    </h5>
                                    <div className="accordian-checklist">
                                        <input
                                            type="checkbox"
                                            name="check1"
                                            id="check1"
                                            className="me-2"
                                        />
                                        <label htmlFor="check1">
                                            Should have enough shade free area. Need around 100 sq.
                                            feet per kW
                                        </label>
                                    </div>
                                    <div className="accordian-checklist mt-2">
                                        <input
                                            type="checkbox"
                                            name="check2"
                                            id="check2"
                                            className="me-2"
                                        />
                                        <label htmlFor="check2">
                                            Should have electricity connection with sanction load at
                                            least equal to solar system size
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    Checklist to qualify for subsidy under PM Surya Ghar Yojana
                                </button>
                            </h2>
                            <div
                                id="collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h5 className="accordian-sub-heading">
                                        All of the following conditions should be met:{" "}
                                    </h5>
                                    <div className="accordian-checklist">
                                        <input
                                            type="checkbox"
                                            name="check3"
                                            id="check3"
                                            className="me-2"
                                        />
                                        <label htmlFor="check3">
                                            Should have an active electricity connection with a discom
                                            company
                                        </label>
                                    </div>
                                    <div className="accordian-checklist mt-2">
                                        <input
                                            type="checkbox"
                                            name="check4"
                                            id="check4"
                                            className="me-2"
                                        />
                                        <label htmlFor="check4">
                                            Minimum solar system size installed should be 1kW
                                        </label>
                                    </div>
                                    <div className="accordian-checklist mt-2">
                                        <input
                                            type="checkbox"
                                            name="check5"
                                            id="check5"
                                            className="me-2"
                                        />
                                        <label htmlFor="check5">
                                            Should not have availed subsidy before for solar related
                                            to projects
                                        </label>
                                    </div>
                                    <div className="accordian-checklist mt-2">
                                        <input
                                            type="checkbox"
                                            name="check6"
                                            id="check6"
                                            className="me-2"
                                        />
                                        <label htmlFor="check6">
                                            Installation should be done by empanelled solar panel
                                            installer
                                        </label>
                                    </div>
                                    <div className="accordian-checklist mt-2">
                                        <input
                                            type="checkbox"
                                            name="check7"
                                            id="check7"
                                            className="me-2"
                                        />
                                        <label htmlFor="check7">
                                            Components used should be manufactured by companies in the
                                            ALMM list
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                >
                                    What is the process to install Solar System with
                                    Solarvipani.com?
                                </button>
                            </h2>
                            <div
                                id="collapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <div className="accordian-list">
                                        <ol>
                                            <li>Book a consultation call:</li>
                                            <li className="mt-3">
                                                Receive the Free ‘Solar Vipani Diagnostic Report’:
                                                <ul
                                                    style={{ listStyle: "disc", paddingLeft: "1.2rem" }}
                                                >
                                                    <li>
                                                        Detailed analysis of your solar potential and needs
                                                    </li>
                                                    <li>Personalized recommendations</li>
                                                </ul>
                                            </li>
                                            <li className="mt-3">
                                                Schedule a site visit: Assess the site for installation
                                                suitability
                                            </li>
                                            <li className="mt-3">
                                                Get a detailed quotation: Understand the breakdown of
                                                expenses
                                            </li>
                                            <li className="mt-3">
                                                Approve the quotation and make a partial payment
                                            </li>
                                            <li className="mt-3">
                                                Paper work, Material transfer and System installation
                                            </li>
                                            <li className="mt-3">
                                                System testing and discom approval:
                                            </li>
                                            <li className="mt-3">
                                                System starts generating electricity
                                            </li>
                                            <li className="mt-3">
                                                Complete subsidy documentation and remaining payment
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                >
                                    What is net metering?
                                </button>
                            </h2>
                            <div
                                id="collapseSix"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Net metering is a billing arrangement that enables
                                        homeowners with solar panel systems to receive credit for
                                        excess electricity they generate and feed back into the
                                        grid.
                                    </h6>
                                    <h6 className="accordian-desc mt-3">
                                        Under net metering, billing is done in the following way: if
                                        you use 80 units of electricity in a month but your solar
                                        panels produce 100 units, you won't get a bill for that
                                        month, and the extra 20 units will be saved as credits for
                                        future use
                                    </h6>
                                    <h6 className="accordian-desc mt-3">
                                        On the other hand, if you use 120 units but only generate
                                        100 units, you'll need to pay for the extra 20 units unless
                                        you have credits from previous months. If you have credits,
                                        those would be used to reduce your bill. For instance, if
                                        you have 10 credits, you'll only need to pay for 10 units
                                        (20 - 10 = 10 units).
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                >
                                    What appliances can run on the on grid solar system ?
                                </button>
                            </h2>
                            <div
                                id="collapseSeven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        You can keep using all the appliances in your home without
                                        any interruption. This includes things like tube lights, LED
                                        bulbs, induction cookers, refrigerators, washing machines,
                                        air conditioners, and even electric vehicles. Since the on
                                        grid solar system is connected to the grid, any extra energy
                                        and load you need is automatically supplied by the grid,
                                        ensuring your energy needs are always met.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                >
                                    How is installing a Solar Panel System financially beneficial
                                    ?
                                </button>
                            </h2>
                            <div
                                id="collapseEight"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEight"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h5 className="accordian-sub-heading">
                                        Installing a solar system will make your electricity cheaper
                                        than buying it from the utility company.
                                    </h5>
                                    <div className="accordian-list">
                                        <ul className="p-0 list-unstyled">
                                            <li>
                                                Here’s a quick calculation:
                                                <ul
                                                    style={{ listStyle: "disc", paddingLeft: "1.2rem" }}
                                                >
                                                    <li>
                                                        A 3kW solar system costs about ₹1.5 lakh after
                                                        subsidies.
                                                    </li>
                                                    <li>
                                                        It generates around 250 units of electricity per
                                                        month.
                                                    </li>
                                                    <li>
                                                        If you buy 250 units from the utility company, it
                                                        costs about ₹2300. Electricity costs around ₹8.5 per
                                                        unit
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="mt-2">
                                                So, by installing a 3kW system, you save ₹2300 per
                                                month.
                                            </li>
                                            <li className="mt-2">
                                                Assuming the system lasts at least 20 years and operates
                                                at 80% efficiency, the average cost per unit of solar
                                                electricity is:
                                            </li>
                                            <li className="mt-2">
                                                Total system cost / Total units generated over its
                                                lifetime = ₹1,50,000 / (250 units x 12 months x 20
                                                years) = ₹3.12 per unit.
                                            </li>
                                            <li className="mt-2">
                                                As shown, even if utility rates stay the same, you would
                                                still save a lot of money.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                >
                                    How will my cash flows change if I install a solar system on
                                    emi ?
                                </button>
                            </h2>
                            <div
                                id="collapseNine"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingNine"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Let's say you use 250 units of electricity each month and
                                        your monthly bill is around ₹. 2300.
                                    </h6>
                                    <h6 className="accordian-desc mt-3">
                                        You decide to install a 3kW solar panel system that costs ₹
                                        225,000. You can get a subsidy of up to ₹ 78,000 within 3
                                        months.
                                    </h6>
                                    <h6 className="accordian-desc mt-3">
                                        To cover the cost, you take a loan from a government bank at
                                        a 7% interest rate, with a 10% down payment and a payback
                                        period of 4 years.
                                    </h6>
                                    <div className="accordian-list">
                                        <ul className="p-0 list-unstyled">
                                            <li>
                                                Here’s how your cash flows will look:
                                                <ul
                                                    style={{ listStyle: "disc", paddingLeft: "1.2rem" }}
                                                >
                                                    <li>
                                                        1st month: ₹ 22,500 down payment + ₹ 4,780 emi
                                                    </li>
                                                    <li>2nd month: ₹ 4,780 emi</li>
                                                    <li>3rd month: ₹ 4,780 emi</li>
                                                </ul>
                                            </li>
                                            <li className="mt-2">
                                                After receiving the subsidy, the EMI reduces to Rs 2,875
                                                for the rest of the payback period.
                                            </li>
                                            <li className="mt-2">
                                                If you choose a loan from a financing company at a 14%
                                                interest rate, with the same 10% down payment and 4-year
                                                payback period, your cash flows will be:
                                                <ul
                                                    style={{ listStyle: "disc", paddingLeft: "1.2rem" }}
                                                >
                                                    <li>1st month: ₹ 22,500 down payment + ₹ 5,460</li>
                                                    <li>2nd month: ₹ 5,460</li>
                                                    <li>3rd month: ₹ 5,460</li>
                                                </ul>
                                            </li>
                                            <li className="mt-2">
                                                After receiving the subsidy, the EMI reduces to R₹ 3,100
                                                for the rest of the payback period.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTen">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen"
                                    aria-expanded="false"
                                    aria-controls="collapseTen"
                                >
                                    What is the ALMM list?
                                </button>
                            </h2>
                            <div
                                id="collapseTen"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTen"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        The Ministry of New and Renewable Energy (MNRE) issued the
                                        ALMM order, “Approved Models and Manufacturers of Solar
                                        Photovoltaic Modules (Requirement for Compulsory
                                        Registration) Order, 2019” on 02.01.2019. The list is
                                        prepared by the government to ensure reliability and quality
                                        in customer interests. It contains a list of solar panels
                                        from approved manufacturers that can be used by the
                                        consumers in order to claim subsidies or any other incentive
                                        under government schemes.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEleven">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseEleven"
                                    aria-expanded="false"
                                    aria-controls="collapseEleven"
                                >
                                    What is an empanelled solar panel installer ?
                                </button>
                            </h2>
                            <div
                                id="collapseEleven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEleven"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Under MNRE order released 02.02.2022, it was decided that
                                        solar panel installation vendors should register with
                                        Distribution companies and get empanelled after fulfilling
                                        certain qualification criteria. This was done to ensure that
                                        the vendors installing the rooftop solar plants have the
                                        requisite expertise, follow minimum specifications and
                                        standards specified by the MNRE and maintain the plant for
                                        at least five years from the date of installation,
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwelven">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwelven"
                                    aria-expanded="false"
                                    aria-controls="collapseTwelven"
                                >
                                    What is the warranty on solar panels and inverters ?
                                </button>
                            </h2>
                            <div
                                id="collapseTwelven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwelven"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Performance warranty on solar panel modules is usually
                                        between 20 to 25 years Warranty for solar inverters is
                                        usually between 7 to 10 years
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThirteen">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirteen"
                                    aria-expanded="false"
                                    aria-controls="collapseThirteen"
                                >
                                    How is a solar system's performance in the rainy season ?
                                </button>
                            </h2>
                            <div
                                id="collapseThirteen"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThirteen"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Solar panels produce less electricity during the rainy
                                        season because of clouds and rain, which reduce sunlight.
                                        However, they don’t stop working completely. Solar systems
                                        are designed with this in mind, considering the average
                                        yearly electricity generation and the net metering policy of
                                        the electricity company.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingForteen">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseForteen"
                                    aria-expanded="false"
                                    aria-controls="collapseForteen"
                                >
                                    What is the maintenance requirement of solar panel systems ?
                                </button>
                            </h2>
                            <div
                                id="collapseForteen"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingForteen"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    <h6 className="accordian-desc">
                                        Solar panel systems require minimal maintenance as there are
                                        no moving parts in it. Regular cleaning is important to
                                        remove dust, dirt, and debris that can block sunlight. This
                                        can be done a few times a year, depending on the local
                                        environment. It's also essential to check for any shading
                                        from nearby trees or buildings and trim if necessary.
                                        Overall, keeping the panels clean and ensuring they're in
                                        good condition will help maintain their efficiency and
                                        longevity.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-top-brand-section mt-5">
                    <h5 className="home-top-brand-section-text">Top Solar Brands</h5>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/polycab-solar-inverter.png"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/Havells-solar-inverter.png"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/Adani-solar-panel.jpg"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4 mt-md-0 mt-3">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/Waree-solar-panel.jpg"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4 mt-md-0 mt-3">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/growatt-solar-inverter.png"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4 mt-md-0 mt-3">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/vikram-solar-panel.png"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/servotech-solar-panel.jpg"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/Luminar-Solar.png"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-4">
                            <div className="home-top-brand-section-img">
                                <img
                                    src="./img/microtek-solar.jpeg"
                                    alt="top-brand"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-important-links-section">
                    <h5 className="home-important-links-section-text">
                        Important Links{" "}
                    </h5>
                    <a
                        className="important-link"
                        href="https://mnre.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Ministry on New and Renewable Energy
                    </a>
                    <a
                        className="important-link"
                        href="https://www.pmsuryaghar.gov.in/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        PM Surya Ghar Yojana
                    </a>
                    <a
                        className="important-link"
                        href="https://cea.nic.in/?lang=en"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Central Electricity Authority
                    </a>
                    <a
                        className="important-link"
                        href="https://www.seci.co.in/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Solar Energy Corporation of India
                    </a>
                    <a
                        className="important-link"
                        href="https://www.ireda.in/home"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Indian Renewable Energy Development Agency Limited
                    </a>
                    <a
                        className="important-link"
                        href="https://bankofindia.co.in/personal-loan/star-rooftop-solar-panel-finance-loan"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Bank of India
                    </a>
                    <a
                        className="important-link"
                        href="https://www.unionbankofindia.co.in/english/urts.aspx"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Union Bank of India
                    </a>
                    <a
                        className="important-link"
                        href="https://www.iexindia.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Indian Energy Exchange
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Home;
