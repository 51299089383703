import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useLocation, useNavigate, useParams } from "react-router-dom";

const Blogs = () => {

    const { cityParam, url } = useParams();

    const location = useLocation();
    const navigation = useNavigate();

    const [blogData, setBlogData] = useState([]);

    const [currentPage, setcurrentPage] = useState(1)
    const [blogPerPage, setblogPerPage] = useState(5);


    // const page= Math.ceil(blogData.length / blogPerPage);
    // const arr= Array(page);
    // console.log("Pagination++", arr);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://admin.solarvipani.com/blogs");

                setBlogData(response.data.data);


            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        document.title = "Blogs - Solar Vipani";
    }, []);

    const handleSetId = (data) => {
        // localStorage.setItem("blogID", data?.id);
        setTimeout(() => {
            navigation(`/blogs/${data.url}`);
        }, 300);
    };


    const indexofLastPage = currentPage * blogPerPage;
    const indexofFirstPage = indexofLastPage - blogPerPage;
    const currentBlog = blogData.slice(indexofFirstPage, indexofLastPage)
    // console.log(currentBlog);

    const Pagination = (pageNumber) => {
        setcurrentPage(pageNumber);
    }


    // User-Quote
    const [formData, setFormData] = useState({
        systemSize: "",
        type: "",
        pageType: "",
        name: "",
        email: "----",
        pinCode: "",
        gclid_field: "",
        phoneNumber: "",
        comment: "",
        state: "----",
        city: "----",
    });

    useEffect(() => {
        let pageType = "";
        if (location.pathname === "/blog/pm-surya-ghar-yojana") {
            // pageType = "pm-surya-ghar-yojana";
            pageType = window.location.href;
        } else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
            // pageType = "cost-of-solar-on-grid-system";
            pageType = window.location.href;
        } else if (location.pathname === `/solar-panel-installer-directory/${cityParam}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/blogs") {
            pageType = window.location.href;
        } else if (location.pathname === `/blogs/${url}`) {
            pageType = window.location.href;
        } else if (location.pathname === "/") {
            // pageType = "Home";
            pageType = window.location.href;
        }

        setFormData({
            ...formData,
            pageType: pageType,
        });
    }, [location.pathname]);

    const handleChangeBook = (e) => {
        const { name, value } = e.target;

        if (name === "phoneNumber") {
            const input = value.replace(/\D/g, "").slice(0, 15);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else if (name === "state") {
            setFormData((prevData) => ({
                ...prevData,
                state: value,
                city: "",
            }));
        } else if (name === "city") {
            setFormData((prevData) => ({
                ...prevData,
                city: value,
            }));
        } else if (name === "pinCode") {
            const input = value.replace(/\D/g, "").slice(0, 6);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/user-quote",
                formData
            );

            if (!response.data.res) {
                toast.error("Quate is not added!");
            } else {
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    state: "",
                    city: "",
                    type: "",
                    systemSize: "",
                    pinCode: "",
                    gclid_field: "",
                    pageType: "",
                    comment: "",
                });

                navigation("/thanks");
            }
        } catch (error) {
            toast.error("Error fetch form data" + error.message);
        }
    };



    return (
        <>
            <section className="blog_section ">
                <div className="container">
                    <div className="top_text">
                        <h1>Blogs</h1>
                    </div>

                    {blogData.length > 0 ? (
                        currentBlog.map((blog, i) => {
                            // let redirectTo = null;

                            // if (blog.url) {
                            //   redirectTo = `/blogs-details-url/${blog.id}`;
                            // } else if (blog.details) {
                            //   redirectTo = `/blogs-details/${blog.id}`;
                            // }

                            return (
                                <div className="how_work blog_box" key={i}>
                                    <div className="top_text">
                                        <h5>{blog.title}</h5>
                                    </div>

                                    <div className="text">
                                        <p className="mb-3">{blog.description}</p>
                                    </div>

                                    {/* {redirectTo ? ( */}
                                    <button className="blog_btn" onClick={() => handleSetId(blog)}>
                                        Read More
                                    </button>
                                    {/* ) : null} */}
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center">
                            <h1>Blogs not found</h1>
                        </div>
                    )}

                    {/* Pagination */}
                    <div className="paginate d-flex justify-content-center mt-5">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                    <a className="page-link cursor-pointer" onClick={() => Pagination(currentPage - 1)} aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>

                                {/* <li className="page-item"><a className="page-link" href="#" >1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li> */}

                                {Array.from({ length: Math.ceil(blogData.length / blogPerPage) }, (_, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                    >
                                        <a
                                            className="page-link cursor-pointer"
                                            onClick={() => Pagination(index + 1)}
                                        >
                                            {index + 1}
                                        </a>
                                    </li>
                                ))}

                                <li className={`page-item ${currentPage === Math.ceil(blogData.length / blogPerPage) ? "disabled" : ""}`}>
                                    <a className="page-link cursor-pointer" onClick={() => Pagination(currentPage + 1)} aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="blog-right-side mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="text mb-3">
                                {/* <h5>Book Consultation Call to get a Quote</h5> */}
                                <h5>Book Free Consultation To Get A Quote</h5>
                            </div>
                            <div className="form_fields row">
                                <div className="col-12 mb-3">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChangeBook}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="phoneNumber" className="form-label">
                                        {/* Phone */}
                                        Mobile No
                                    </label>
                                    <input
                                        type="number"
                                        name="phoneNumber"
                                        className="form-control"
                                        id="phoneNumber"
                                        placeholder="Mobile No"
                                        value={formData.phoneNumber}
                                        onChange={handleChangeBook}
                                        required
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-3">
                                    <label htmlFor="pinCode" className="form-label">
                                        Pin Code
                                    </label>
                                    <input
                                        type="number"
                                        name="pinCode"
                                        className="form-control"
                                        id="pinCode"
                                        placeholder="Pin Code"
                                        value={formData.pinCode}
                                        onChange={handleChangeBook}
                                        required
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="type" className="form-label">
                                            Type of Solar
                                        </label>
                                        <select
                                            className="form-select form-select-lg mb-3"
                                            id="type"
                                            name="type"
                                            required
                                            onChange={handleChangeBook}
                                            value={formData.type}
                                        >
                                            <option value="">Select</option>
                                            <option value="Residential - Independant home">
                                                Residential - Independant home
                                            </option>
                                            <option value="Residential - Apartment/Housing society">
                                                Residential - Apartment/Housing society
                                            </option>
                                            <option value="Commercial/Business">
                                                Commercial/Business
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 mb-3">
                                    <label htmlFor="comment" className="form-label">
                                        Comment (optional)
                                    </label>
                                    <textarea
                                        className="form-control"
                                        name="comment"
                                        id="comment"
                                        placeholder="Comment (optional)"
                                        value={formData.comment}
                                        onChange={handleChangeBook}
                                        rows={3}
                                    />
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="submit_btn w-100">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Blogs;
