import React from 'react'

const ThankMessage = () => {
    return (
        <>

            <section className="solar-details-section thank-section">
                <div className="container">
                    <div className="thank-message">
                        <p>Thank You</p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ThankMessage
