import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import StateValue from "../data/state3.json";
import CityValue from "../data/city.json";
import axios from "axios";
import { NavLink } from "react-router-dom";
import SolarDetails from "./SolarDetails";
import toast from "react-hot-toast";

const Directory2 = () => {

	const { cityParam, url } = useParams();

	const navigation = useNavigate();
	const location = useLocation();

	const [state, setState] = useState({
		selectedState: "",
		selectedDistrict: "",
		selectedCity: "",
	});

	const city = location.state;
	const [cityselect, setcityselect] = useState(false || city);

	const [citys, setcitys] = useState([]);

	const [csvData, setCsvData] = useState([]);
	const stateNames = Object.keys(StateValue);

	const [pagination, setPagination] = useState({
		total: 0,
		lastPage: 0,
		prevPage: null,
		nextPage: null,
		perPage: 9,
		currentPage: 1,
	});

	const handleRedirect = async (data) => {
		// console.log(data.slug);

		try {
			// const response = await axios.get(
			// 	`https://admin.solarvipani.com/business-details/${id}`
			// );
			// const data = response.data.data;

			if (data?.slug === null || !data.slug) {
				navigation(`/solar-panel-installer`, { state: data });
			} else {
				navigation(`/solar-panel-installer/${data.slug}`, { state: data });
			}
		} catch (error) {
			console.error("API Error with ID:", error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "state") {
			setState((prevData) => ({
				...prevData,
				selectedState: value,
				selectedDistrict: "",
				selectedCity: "",
			}));

			setCsvData([]);
		}
		else if (name === "district") {
			setState((prevData) => ({
				...prevData,
				selectedDistrict: value,
				selectedCity: "",
			}));

			setCsvData([]);
		}
		else if (name === "city") {
			setState((prevData) => ({
				...prevData,
				selectedCity: value,
			}));

			setcityselect(true);

			setPagination((prev) => ({
				...prev,
				currentPage: 1,
			}));

			navigation(`/solar-panel-installer-directory/${value.toLowerCase()}`);
		}
	};

	const selectedStateData = state.selectedState
		? StateValue[state.selectedState].data
		: [];

	const FetchCityData = async (district) => {
		try {
			const response = await axios.get(
				`https://admin.solarvipani.com/citys/${district}`
			);
			setcitys(response.data.city);

		} catch (error) {
			console.error("citys-API-Error++", error);
		}
	}

	useEffect(() => {
		if (state?.selectedDistrict) {
			FetchCityData(state?.selectedDistrict);
		}
	}, [state?.selectedDistrict]);


	const fetchData = async (page) => {
		try {
			const response = await axios.get(
				// `https://admin.solarvipani.com/business-list-new/${state.selectedDistrict.toLowerCase()}?page=${page}&limit=${pagination?.perPage}`
				`https://admin.solarvipani.com/business-list-city/${cityParam}?page=${page}&limit=${pagination.perPage}`
			);

			setCsvData(response.data.data);

			const newPagination = response.data.pagination;
			setPagination((prev) => ({
				...prev,
				total: newPagination.total || prev.total,
				lastPage: newPagination.lastPage || Math.ceil(prev.total / prev.perPage),
				prevPage: newPagination.prevPage,
				nextPage: newPagination.nextPage,
				perPage: newPagination.perPage,
				currentPage: newPagination.currentPage,
			}));

		} catch (error) {
			console.error("API Error:", error);
		}
	};

	useEffect(() => {
		if (pagination?.currentPage) {
			fetchData(pagination?.currentPage);
		}
	}, [cityParam, pagination?.currentPage]);

	// Title City-Name 
	useEffect(() => {
		const city = state.selectedCity || "Your Area";
		document.title = `Solar System Installers in ${city} - Solar Vipani`;
	}, [state.selectedCity]);

	// Parameter pass city to direct selected input & data
	useEffect(() => {
		if (cityParam) {
			// Normalize cityParam to lowercase
			const normalizedCityParam = cityParam.toLowerCase();

			let foundState = "";
			let foundDistrict = "";
			let foundCity = "";

			// State
			Object.keys(StateValue).forEach((stateKey) => {
				// District
				const districts = StateValue[stateKey]?.data || [];
				districts.forEach((district) => {
					// City
					const cities = CityValue[district]?.data || [];
					cities.forEach((city) => {
						if (city.toLowerCase() === normalizedCityParam) {
							foundState = stateKey;
							foundDistrict = district;
							foundCity = city;
						}
					})
				});
			});


			if (foundState && foundDistrict) {
				setState({
					selectedState: foundState,
					selectedDistrict: foundDistrict,
					selectedCity: foundCity,
				});
			}
		}
	}, [cityParam]);


	// Script Data Add 
	useEffect(() => {
		if (csvData.length > 0) {
			const generateJSONLD = () => {
				const items = csvData.map((v, i) => ({
					"@type": "ListItem",
					"position": i + 1,
					"item": {
						"@type": "LocalBusiness",
						"name": v?.name || "No Name",
						"address": {
							"@type": "PostalAddress",
							"streetAddress": v?.address || "No Address",
							"addressLocality": state.selectedDistrict || "Unknown",
							"addressRegion": state.selectedState || "Unknown",
							"addressCountry": "IN"
						},
						"telephone": v?.phoneNumber || "No Phone",
						"url": `https://solarvipani.com/solar-panel-installer/${v?.slug}` || "No URL",
						"plusCode": v?.mapPin || "No Plus Code"
					}
				}));

				const jsonLd = {
					"@context": "https://schema.org",
					"@type": "ItemList",
					"name": `Solar Panel Installers in ${state.selectedDistrict || "Your Area"}`,
					"description": `A list of top solar panel installers in ${state.selectedDistrict || "your area"} with their contact details, services offered, pricing, reviews and ratings. Solar Panel Installation for Homes and Business. Best place to start your solar journey`,
					"itemListElement": items,
					"additionalType": "ProductCollection",
					"hasOfferCatalog": {
						"@type": "OfferCatalog",
						"name": `Popular Solar System Sizes in ${state.selectedDistrict || "Your Area"}`,
						"itemListElement": [
							{
								"@type": "Product",
								"name": "1 kW Solar System",
								"Image": "https://admin.solarvipani.com/newimage/1kwsolarpanel.avif",
								"offers": {
									"@type": "Offer",
									"priceCurrency": "INR",
									"Price": "50000",
									"availability": "https://schema.org/InStock"
								}
							},
							{
								"@type": "Product",
								"name": "3 kW Solar System",
								"Image": "https://admin.solarvipani.com/newimage/3kwsolarpanel.jpg",
								"offers": {
									"@type": "Offer",
									"priceCurrency": "INR",
									"Price": "95000",
									"availability": "https://schema.org/InStock"
								}
							},
							{
								"@type": "Product",
								"name": "5 kW Solar System",
								"Image": "https://admin.solarvipani.com/newimage/5kwsolarpanel.jpeg",
								"offers": {
									"@type": "Offer",
									"priceCurrency": "INR",
									"Price": "145000",
									"availability": "https://schema.org/InStock"
								}
							}
						]
					}
				};

				let script = document.querySelector('script[type="application/ld+json"]');

				if (script) {
					script.innerHTML = JSON.stringify(jsonLd);
				} else {
					script = document.createElement('script');
					script.type = 'application/ld+json';
					script.innerHTML = JSON.stringify(jsonLd);
					document.head.appendChild(script);
				}
			};

			generateJSONLD();
		}
	}, [csvData, state.selectedDistrict, state.selectedState])

	const Pagination = (pageNumber) => {
		setPagination((pre) => ({
			...pre,
			currentPage: pageNumber,
		}));
	}

	// User-Quote
	const [formData, setFormData] = useState({
		systemSize: "",
		type: "",
		pageType: "",
		name: "",
		email: "----",
		pinCode: "",
		gclid_field: "",
		phoneNumber: "",
		comment: "",
		state: "----",
		city: "----",
	});

	useEffect(() => {
		let pageType = "";
		if (location.pathname === "/blog/pm-surya-ghar-yojana") {
			// pageType = "pm-surya-ghar-yojana";
			pageType = window.location.href;
		} else if (location.pathname === "/blog/Cost-of-solar-on-grid-system") {
			// pageType = "cost-of-solar-on-grid-system";
			pageType = window.location.href;
		} else if (location.pathname === `/solar-panel-installer-directory/${cityParam}`) {
			pageType = window.location.href;
		} else if (location.pathname === "/blogs") {
			pageType = window.location.href;
		} else if (location.pathname === `/blogs/${url}`) {
			pageType = window.location.href;
		} else if (location.pathname === "/") {
			// pageType = "Home";
			pageType = window.location.href;
		}

		setFormData({
			...formData,
			pageType: pageType,
		});
	}, [location.pathname]);

	const handleChangeBook = (e) => {
		const { name, value } = e.target;

		if (name === "phoneNumber") {
			const input = value.replace(/\D/g, "").slice(0, 15);
			setFormData((prevData) => ({
				...prevData,
				[name]: input,
			}));
		} else if (name === "state") {
			setFormData((prevData) => ({
				...prevData,
				state: value,
				city: "",
			}));
		} else if (name === "city") {
			setFormData((prevData) => ({
				...prevData,
				city: value,
			}));
		} else if (name === "pinCode") {
			const input = value.replace(/\D/g, "").slice(0, 6);
			setFormData((prevData) => ({
				...prevData,
				[name]: input,
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await axios.post(
				"https://admin.solarvipani.com/user-quote",
				formData
			);

			if (!response.data.res) {
				toast.error("Quate is not added!");
			} else {
				setFormData({
					name: "",
					phoneNumber: "",
					email: "",
					state: "",
					city: "",
					type: "",
					systemSize: "",
					pinCode: "",
					gclid_field: "",
					pageType: "",
					comment: "",
				});

				navigation("/thanks");
			}
		} catch (error) {
			toast.error("Error fetch form data" + error.message);
		}
	};


	return (
		<div className="solar_calculator_section">
			<div className="container">
				<div className="top_text d-flex align-items-center justify-content-between flex-wrap">
					<h1> Solar System Installers in {state.selectedCity || "Your Area"}</h1>
					<NavLink to={"/business-form"} className="btn btn-primary">
						Add Business
					</NavLink>
				</div>

				<div className="row">
					{cityselect &&
						<div className="col-12  mb-5">
							<div className="solar_calculator_left_side">
								<form>
									<div className="mb-4">
										<label htmlFor="state" className="form-label">
											State
										</label>
										<select
											className="form-select"
											id="state"
											name="state"
											onChange={handleChange}
											value={state.selectedState}
										>
											<option value={""}>Select</option>
											{stateNames.map((v, i) => (
												<option value={v} key={i}>
													{StateValue[v].name}
												</option>
											))}
										</select>
									</div>
									{selectedStateData.length > 0 ? (
										<div className="mb-4">
											<label htmlFor="district" className="form-label">
												District
											</label>
											<select
												className="form-select"
												id="district"
												name="district"
												onChange={handleChange}
												value={state.selectedDistrict}
											>
												<option value={""}>Select</option>
												{selectedStateData.map((district, i) => (
													<option value={district} key={i}>
														{district}
													</option>
												))}
											</select>
										</div>
									) : (
										""
									)}

									{state.selectedDistrict ? (
										<div className="mb-4">
											<label htmlFor="city" className="form-label">
												City/Town/Area
											</label>

											{
												<select
													className="form-select"
													id="city"
													name="city"
													value={state.selectedCity}
													onChange={handleChange}
												>
													<option value={""}>Select</option>
													{
														citys.map((city, i) => (
															<option value={city} key={i}>
																{city}
															</option>
														))
													}
												</select>
											}
										</div>
									) : (
										""
									)}
								</form>
							</div>
						</div>
					}

					{csvData?.length > 0 && cityParam ? (
						<div className="col-12">
							{cityselect && <h4 className="search-result-title mb-4">Search results</h4>}
							<div className="solar_calculator_right_side">
								{Array.isArray(csvData) && csvData.length > 0 ? (
									<div className="row">
										{
											csvData.map((v, i) => {
												return (
													<div
														className="col-lg-4 col-md-6 col-12 mb-3"
														key={i}
													>
														<div className="card h-100">
															<div className="card-body">
																{(v?.note !== "Blank" && v?.note) && (
																	<h5
																		className="card-sub-title"
																		onClick={() => handleRedirect(v)}
																		style={{ cursor: "pointer" }}
																	>
																		{v?.note}
																	</h5>
																)}
																<h5
																	className="card-title"
																	onClick={() => handleRedirect(v)}
																	style={{ cursor: "pointer" }}
																>
																	{v?.name}
																</h5>
																<div
																	className="card-text d-flex mb-3"
																	onClick={() => handleRedirect(v)}
																	style={{ cursor: "pointer" }}
																>
																	<div className="me-2">
																		<i className="fa-solid fa-phone"></i>
																	</div>
																	<div>{v?.phoneNumber}</div>
																</div>
																{v?.website ? (
																	<div
																		className="card-text d-flex mb-3"
																		onClick={() => handleRedirect(v)}
																		style={{ cursor: "pointer" }}
																	>
																		<div className="me-2">
																			<i className="fa-solid fa-globe"></i>
																		</div>
																		<div>
																			<a
																				href={v?.website}
																				target="_blank"
																				rel="noreferrer"
																				className="ellipsis"
																			>
																				{v?.website.replace(
																					/^(https?:\/\/)?|\/$/g,
																					""
																				)}
																			</a>
																		</div>
																	</div>
																) : (
																	""
																)}
																<div
																	className="card-text d-flex"
																	onClick={() => handleRedirect(v)}
																	style={{ cursor: "pointer" }}
																>
																	<div className="me-2">
																		<i className="fa-solid fa-location-dot"></i>
																	</div>
																	<div>{v?.address ? v?.address : "----"}</div>
																</div>
															</div>
														</div>
													</div>
												);
											})
										}
									</div>
								) : (
									<div className="text-center">
										<h1>No data found</h1>
									</div>
								)}
							</div>
						</div>
					) : (
						<div className="text-center mt-5">
							<h1 className="fs-3">No data found for {state.selectedCity || "Your Area"}</h1>
						</div>
					)}

					{/* Pagination */}
					{csvData?.length > 0 && state.selectedCity &&
						<div className="paginate d-flex justify-content-center mt-5">
							<nav aria-label="Page navigation example">
								<ul className="pagination">
									<li className={`page-item ${pagination?.currentPage === 1 ? "disabled" : ""}`}>
										<a className="page-link cursor-pointer" onClick={() => Pagination(pagination.currentPage - 1)} aria-label="Previous">
											<span aria-hidden="true">&laquo;</span>
										</a>
									</li>
									{Array.from({ length: pagination.lastPage }, (_, index) => (
										<li
											key={index}
											className={`page-item ${pagination.currentPage === index + 1 ? "active" : ""}`}
										>
											<a
												className="page-link cursor-pointer"
												onClick={() => Pagination(index + 1)}
											>
												{index + 1}
											</a>
										</li>
									))}
									<li className={`page-item ${pagination?.currentPage === pagination.lastPage ? "disabled" : ""}`}>
										<a className="page-link cursor-pointer" onClick={() => Pagination(pagination.currentPage + 1)} aria-label="Next">
											<span aria-hidden="true">&raquo;</span>
										</a>
									</li>
								</ul>
							</nav>
						</div>
					}
				</div>

				<div className="solar-right-side mt-5">
					<form onSubmit={handleSubmit}>
						<div className="text mb-3">
							{/* <h5>Book Consultation Call to get a Quote</h5> */}
							<h5>Book Free Consultation To Get A Quote</h5>
						</div>
						<div className="form_fields row">
							<div className="col-12 mb-3">
								<label htmlFor="name" className="form-label">
									Name
								</label>
								<input
									type="text"
									name="name"
									className="form-control"
									id="name"
									placeholder="Name"
									value={formData.name}
									onChange={handleChangeBook}
									required
								/>
							</div>
							<div className="col-md-6 col-12 mb-3">
								<label htmlFor="phoneNumber" className="form-label">
									{/* Phone */}
									Mobile No
								</label>
								<input
									type="number"
									name="phoneNumber"
									className="form-control"
									id="phoneNumber"
									placeholder="Mobile No"
									value={formData.phoneNumber}
									onChange={handleChangeBook}
									required
								/>
							</div>
							<div className="col-md-6 col-12 mb-3">
								<label htmlFor="pinCode" className="form-label">
									Pin Code
								</label>
								<input
									type="number"
									name="pinCode"
									className="form-control"
									id="pinCode"
									placeholder="Pin Code"
									value={formData.pinCode}
									onChange={handleChangeBook}
									required
								/>
							</div>
							<div className="col-12">
								<div className="mb-3">
									<label htmlFor="type" className="form-label">
										Type of Solar
									</label>
									<select
										className="form-select form-select-lg mb-3"
										id="type"
										name="type"
										required
										onChange={handleChangeBook}
										value={formData.type}
									>
										<option value="">Select</option>
										<option value="Residential - Independant home">
											Residential - Independant home
										</option>
										<option value="Residential - Apartment/Housing society">
											Residential - Apartment/Housing society
										</option>
										<option value="Commercial/Business">
											Commercial/Business
										</option>
									</select>
								</div>
							</div>
							<div className="col-md-12 col-12 mb-3">
								<label htmlFor="comment" className="form-label">
									Comment (optional)
								</label>
								<textarea
									className="form-control"
									name="comment"
									id="comment"
									placeholder="Comment (optional)"
									value={formData.comment}
									onChange={handleChangeBook}
									rows={3}
								/>
							</div>
							<div className="col-12">
								<button type="submit" className="submit_btn w-100">
									Submit
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Directory2;
