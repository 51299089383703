import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const QueriesForm = () => {

    const { cityParam, url } = useParams();
    const navigation = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        email: "---",
        phoneNumber: "",
        message: "",
        type: "",
    });

    const location = useLocation();

    useEffect(() => {
        let type = "";
        if (location.pathname === "/solar") {
            // type = "Solar System Calculator";
            type = window.location.href;
        } else if (location.pathname === "/electric") {
            // type = "Electricity Consumption Estimator";
            type = window.location.href;
        } else if (location.pathname === "/directory") {
            // type = "Solar System Installers in Your Area";
            type = window.location.href;
        } else if (location.pathname === "/about") {
            // type = "About Us";
            type = window.location.href;
        } else if (location.pathname === "/book-site-visit") {
            // type = "Book Site Visit";
            type = window.location.href;
        } else if (location.pathname === "/") {
            // type = "Home";
            type = window.location.href;
        }

        setFormData({
            ...formData,
            type: type,
        });
    }, [location.pathname]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "phoneNumber") {
            const input = value.replace(/\D/g, "").slice(0, 15);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/user-queries",
                { ...formData }
            );
            if (!response.data.res) {
                toast.error("Quote is not added!");
            } else {
                setFormData({
                    name: "",
                    email: "",
                    phoneNumber: "",
                    message: "",
                    type: "",
                });

                navigation("/thanks");
            }
        } catch (error) {
            toast.error("Error fetching form data: " + error.message);
        }
    };



    return (
        <>
            {window.location.pathname === "/thanks" ||
                window.location.pathname === "/congratulations-detail" ||
                window.location.pathname === "/solar-panel-installer-directory" ||
                window.location.pathname === `/solar-panel-installer-directory/${cityParam}` ||
                window.location.pathname === "/solar-panel-installer-review" ||
                window.location.pathname === "/solar-panel-installer" ||
                window.location.pathname === "/solar-panel-installer" ||
                window.location.pathname === "/blogs" ||
                window.location.pathname === `/blogs/${url}` ||
                /^\/solar-panel-installer\/[^/]+$/.test(window.location.pathname)
                ? (
                    ""
                ) : (
                    <section
                        className="queries-form-section"
                        style={window.location.pathname === "/" ? { paddingTop: "0" } : null}
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div
                                        className={
                                            window.location.pathname === "/"
                                                ? "form_h_area"
                                                : "form_area"
                                        }
                                    >
                                        <form
                                            onSubmit={handleSubmit}
                                            style={
                                                window.location.pathname === "/"
                                                    ? { backgroundColor: "#BBD1BB" }
                                                    : null
                                            }
                                        >
                                            <div className="text mb-lg-3 mb-3">
                                                <h2>If you have any questions, Feel free to reach out</h2>
                                            </div>
                                            <div className="form_fields">
                                                <div className="mb-3 fw-bold">
                                                    <label htmlFor="name" className="form-label">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        id="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="row">
                                                    {/* {window.location.pathname !== "/" && (
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="email" className="form-label">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    id="email"
                                                                />
                                                            </div>
                                                        </div>
                                                    )} */}
                                                    <div className="col-lg-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="phoneNumber" className="form-label">
                                                                Phone
                                                            </label>
                                                            <input
                                                                type="tel"
                                                                name="phoneNumber"
                                                                className="form-control"
                                                                id="phoneNumber"
                                                                value={formData.phoneNumber}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="message" className="form-label">
                                                        Message
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        name="message"
                                                        id="message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        rows={3}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <button type="submit" className="submit_btn w-100">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
        </>
    );
};

export default QueriesForm;