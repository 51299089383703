import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

const NoFound = () => {

    const [error, setError] = useState("");


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://admin.solarvipani.com/business-details-slug/${null}`
                );

                if (response.data.res === false) {
                    setError(response.data.message);
                } else {
                    setError(null);
                }

            } catch (error) {
                setError(error.message);
                toast.error(error.message);
            }
        };

        fetchData();
    }, []);


    return (
        <>

            <section className="solar-details-section">
                <div className="container">
                    {error ? (
                        <div className="error-message">
                            <p>{error}</p>
                        </div>
                    ) :
                        ("")
                    }
                </div>
            </section>

        </>
    )
}

export default NoFound
