import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const Review = () => {

    const navigation = useNavigate();

    const location = useLocation();
    // console.log(location);
    
    const { name, id } = location.state || {};
    const [formData, setFormData] = useState({
        id: id,
        star: 0,
        reviewTitle: "",
        reviewDescription: "",
        displayName: "",
        pincode: "",
        mobileNumber: "",
        dateOfInstallation: "",
        sizeOfSystem: "",
        solarPanelBrand: "",
        inverterBrand: "",
        comment: "",
    });
    
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [dateSelected, setDateSelected] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "mobileNumber") {
            const input = value.replace(/\D/g, "").slice(0, 15);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else if (name === "pincode") {
            const input = value.replace(/\D/g, "").slice(0, 6);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else if (name === "sizeOfSystem") {
            const regex = /^(?:[1-9]\d*|0)?(?:\.\d)?$/;
            if (regex.test(value)) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

        if (name === "dateOfInstallation") {
            setDateSelected(true);
        }
    };

    const validateForm = () => {
        if (formData.star === 0) {
            toast.error("Please provide a star rating.");
            return false;
        }
        if (!formData.reviewTitle.trim()) {
            toast.error("Review Title is required.");
            return false;
        }
        if (!formData.reviewDescription.trim()) {
            toast.error("Review Description is required.");
            return false;
        }
        if (!formData.pincode.trim()) {
            toast.error("Pincode is required.");
            return false;
        }
        if (!formData.dateOfInstallation.trim()) {
            toast.error("Date of Installation is required.");
            return false;
        }
        if (!formData.sizeOfSystem.trim()) {
            toast.error("Size of System is required.");
            return false;
        }
        if (!formData.solarPanelBrand.trim()) {
            toast.error("Solar Panel Brand is required.");
            return false;
        }
        if (!formData.inverterBrand.trim()) {
            toast.error("Inverter Brand is required.");
            return false;
        }
        if (!formData.mobileNumber.trim()) {
            toast.error("Mobile Number is required.");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/business-review",
                formData
            );

            if (!response.data.res) {
                toast.error("Failed to submit review.");
            } else {
                setFormData({
                    id: id,
                    star: 0,
                    reviewTitle: "",
                    reviewDescription: "",
                    displayName: "",
                    pincode: "",
                    mobileNumber: "",
                    dateOfInstallation: "",
                    sizeOfSystem: "",
                    solarPanelBrand: "",
                    inverterBrand: "",
                    comment: "",
                });

                toast.success("Review submitted successfully!");

                navigation("/thankyou");
            }
        } catch (error) {
            toast.error("Error submitting form: " + error.message);
        }
    };

    const handleStarClick = (index) => {
        setRating(index);
        setFormData((prevData) => ({
            ...prevData,
            star: index,
        }));
    };

    return (
        <>
            <div className="review_section">
                <div className="container">
                    <h3 className="review__title">
                        Write a Review about your installation experience with for {name}
                    </h3>
                    <h3 className="review_star_rating_title mt-4">
                        How will you rate your overall experience?
                    </h3>
                    <form onSubmit={handleSubmit} className="review_form">
                        <div className="star-rating">
                            {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                    <button
                                        type="button"
                                        key={index}
                                        className={index <= (hover || rating) ? "on" : "off"}
                                        onClick={() => handleStarClick(index)}
                                        onMouseEnter={() => setHover(index)}
                                        onMouseLeave={() => setHover(rating)}
                                    >
                                        <span className="star">&#9733;</span>
                                    </button>
                                );
                            })}
                        </div>
                        <h3 className="review_form_title mt-2">
                            Write a review so that other customers can understand reasons for
                            your rating
                        </h3>
                        <label htmlFor="reviewTitle" className="form-label mt-4">
                            Review Title*
                        </label>
                        <input
                            type="text"
                            name="reviewTitle"
                            id="reviewTitle"
                            className="form-control"
                            placeholder="Enter Review Title"
                            required
                            value={formData.reviewTitle}
                            onChange={handleChange}
                        />
                        <label htmlFor="reviewDescription" className="form-label mt-4">
                            Review Description*
                        </label>
                        <textarea
                            className="form-control"
                            rows="3"
                            name="reviewDescription"
                            id="reviewDescription"
                            value={formData.reviewDescription}
                            onChange={handleChange}
                        ></textarea>
                        <label htmlFor="displayName" className="form-label mt-4">
                            Your Display Name
                        </label>
                        <input
                            type="text"
                            name="displayName"
                            id="displayName"
                            className="form-control"
                            placeholder="Enter Your Display Name"
                            value={formData.displayName}
                            onChange={handleChange}
                        />
                        <label htmlFor="pincode" className="form-label mt-4">
                            Your Pin code*
                        </label>
                        <input
                            type="number"
                            name="pincode"
                            id="pincode"
                            className="form-control"
                            placeholder="Enter Your Pin code"
                            required
                            value={formData.pincode}
                            onChange={handleChange}
                        />
                        <label htmlFor="dateOfInstallation" className="form-label mt-4">
                            Date of Installation*
                        </label>
                        <div className="position-relative">
                            <input
                                type="month"
                                name="dateOfInstallation"
                                id="dateOfInstallation"
                                className="form-control"
                                required
                                value={formData.dateOfInstallation}
                                onChange={handleChange}
                                style={{ color: dateSelected ? "inherit" : "transparent" }}
                            />
                            {!dateSelected && (
                                <span className="placeholder-text">
                                    Eg. (month year)
                                </span>
                            )}
                        </div>
                        <label htmlFor="sizeOfSystem" className="form-label mt-4">
                            Size of System (kW)*
                        </label>
                        <input
                            type="number"
                            name="sizeOfSystem"
                            id="sizeOfSystem"
                            className="form-control"
                            placeholder="Enter Size of System"
                            value={formData.sizeOfSystem}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="solarPanelBrand" className="form-label mt-4">
                            Solar Panel Brand*
                        </label>
                        <select
                            name="solarPanelBrand"
                            id="solarPanelBrand"
                            className="form-select"
                            required
                            value={formData.solarPanelBrand}
                            onChange={handleChange}
                        >
                            <option value="1">Select solar Panel Brand</option>
                            <option value="2">Abc</option>
                            <option value="3">pqr</option>
                            <option value="4">xyz</option>
                        </select>
                        <label htmlFor="inverterBrand" className="form-label mt-4">
                            Inverter Brand*
                        </label>
                        <select
                            name="inverterBrand"
                            id="inverterBrand"
                            className="form-select"
                            required
                            value={formData.inverterBrand}
                            onChange={handleChange}
                        >
                            <option value="1">Select Inverter Brand</option>
                            <option value="2">Abc</option>
                            <option value="3">pqr</option>
                            <option value="4">xyz</option>
                        </select>
                        <label htmlFor="comment" className="form-label mt-4">
                            Comment
                        </label>
                        <textarea
                            className="form-control"
                            id="comment"
                            rows="3"
                            name="comment"
                            value={formData.comment}
                            onChange={handleChange}
                        ></textarea>
                        <label htmlFor="mobileNumber" className="form-label mt-4">
                            Mobile Number*
                        </label>
                        <input
                            type="number"
                            name="mobileNumber"
                            id="mobileNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            required
                            value={formData.mobileNumber}
                            onChange={handleChange}
                        />
                        <h3 className="bottom-desc mt-4">You mobile number will be used only for verification of the review. It won’t be displayed anywhere and won’t be shared with anybody else. </h3>
                        <button type="submit" className="star-rating-btn mt-3">
                            Submit Review
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Review;
