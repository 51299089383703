import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal, Form, Button } from "react-bootstrap";

const SolarDetails = () => {
    const { slug } = useParams();
    // console.log("slug++", slug);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
    });

    const [state, setState] = useState({
        name: "",
        phoneNumber: "",
        address: "",
        mapPin: "",
        note: "",
    });


    const [modalId, setModalId] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        // if (!Id) {
        //     setError("Error: Invalid ID. Please provide a valid ID.");
        //     return;
        // }

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://admin.solarvipani.com/business-details-slug/${slug}`
                );
                // console.log("Single-Business++", response);

                if (response.data.res === false) {
                    setError(response.data.message);
                } else {
                    setState(response.data.data);
                    setError(null);
                }

            } catch (error) {
                setError(error.message);
                toast.error(error.message);
            }
            finally {
                setLoading(false)
            }
        };

        fetchData();
    }, [slug]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handelRedirect = () => {
        navigate("/solar-panel-installer-review", {
            state: { name: state.name, id: state.id },
        });
    };

    const handleId = (id) => {
        handleShow(false);
        setModalId(id);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://admin.solarvipani.com/call-request",
                {
                    id: modalId,
                    name: formData.name,
                    phoneNumber: formData.phoneNumber,
                }
            );

            toast.success("Successfully Call Request sent!");

            setFormData({
                name: "",
                phoneNumber: "",
            });
            return response.data;
        } catch (error) {
            toast.error("Error fetch form data" + error.message);
        }
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;

        if (name === "phoneNumber") {
            const input = value.replace(/\D/g, "").slice(0, 10);
            setFormData((prevData) => ({
                ...prevData,
                [name]: input,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    return (
        <>
            <section className="solar-details-section">
                <div className="container">
                    {loading ? (
                        ""
                    ) : error ? (
                        <div className="error-message">
                            <p>{error}</p>
                        </div>
                    ) : (
                        <>
                            <h4 className="solar-title">{state?.name}</h4>
                            {(state?.note !== "Blank" && state?.note) && (
                                <h6 className="solar-sub-title">{state?.note}</h6>
                            )}
                            <button
                                className="solar-details-btn mb-4"
                                onClick={handelRedirect}
                            >
                                Write a Review
                            </button>
                            <h5 className="phone-title">Phone number</h5>
                            <strong>{state?.phoneNumber}</strong>
                            <h5 className="phone-title mt-3">Address</h5>
                            <h6 className="address-desc">{state?.address}</h6>
                            <div className="d-flex-align-items-center mt-4">
                                <a
                                    className="solar-details-btn"
                                    href={`tel:${state?.phoneNumber}`}
                                >
                                    Call
                                </a>
                                <a
                                    className="solar-details-btn ms-4"
                                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                        state?.mapPin
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Direction
                                </a>
                            </div>
                            <div className="product-services mt-5">
                                <h4 className="service-title">Product and services</h4>
                                <ul className="product-list mt-3">
                                    <li className="mb-3">Solar Panel Installers for homes</li>
                                    <li className="mb-3">
                                        Solar Panel Installers for Apartments
                                    </li>
                                    <li className="mb-3">
                                        Solar Panel Installers for Housing societies
                                    </li>
                                </ul>
                            </div>
                            <button
                                className="solar-details-btn"
                                onClick={() => handleId(state?.id)}
                            >
                                Request call back
                            </button>
                        </>
                    )}
                </div>
            </section>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Form action="#" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label className="form-label fw-bold">Mobile</Form.Label>
                            <Form.Control
                                type="tel"
                                name="phoneNumber"
                                className="form-control"
                                id="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleFormChange}
                                placeholder="Mobile Number"
                                pattern="[0-9]*"
                                maxLength={10}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="form-label fw-bold">Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleFormChange}
                                className="form-control"
                                id="name"
                                placeholder="Name"
                                required
                            />
                        </Form.Group>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button variant="primary" type="submit" onClick={handleClose}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SolarDetails;