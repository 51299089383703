import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const About = () => {
  // const userId = "";
  // const clickTime = new Date(Date.now()).toLocaleString();
  // console.log({ userId, page: "About", clickTime });

  useEffect(() => {
    document.title = "About Us - Solar Vipani";
  }, []);


  return (
    <>
      <div className="container">
        <div className="about-title">
          <h1>About us</h1>
        </div>
        <div className="about-description">
          <p>
            Welcome to Solarvipani.com where your journey to harnessing the
            power of solar energy begins with confidence and trust. We are your
            premier marketplace aggregator dedicated to connecting residential
            and business customers with top-tier Solar EPC (Engineering,
            Procurement, and Construction) companies, ensuring that you make
            informed decisions backed by credible information.
          </p>
        </div>
        <div className="about-sub-title">
          <h5>Our Mission</h5>
        </div>
        <div className="about-description">
          <p>
            At Solarvipani.com, our mission is to empower homeowners to
            transition to sustainable and renewable energy solutions seamlessly.
            We understand that choosing the right Solar EPC company is a
            significant decision, and our platform is designed to simplify this
            process by providing you with reliable and verified information.
          </p>
        </div>
        <div className="about-sub-title">
          <h5>Why Choose Us:</h5>
        </div>
        <div className="about-description">
          <h5 className="mb-4">
            <span>1. Verified Solar EPC Companies:</span>
            <p className="ms-3">
              Your peace of mind is our priority. We meticulously vet and verify
              each Solar EPC company featured on our platform, ensuring that
              they meet the highest industry standards. You can trust that the
              companies you find on Solarvipani.com are reputable and reliable.
            </p>
          </h5>
          <h5 className="mb-4">
            <span>
              2. Credible Information and Streamlined Decision-Making:
            </span>

            <p className="ms-3">
              Our platform provides you with credible details about each
              company. We provide quotations from multiple companies working in
              your area. Navigating the solar energy landscape can be
              overwhelming. Our platform simplifies the decision-making process
              tailored to your residential or business needs. Make informed
              choices with ease and confidence.
            </p>
          </h5>
          <h5 className="mb-4">
            <span>3. Customer-Centric Approach:</span>
            <p className="ms-3">
              We prioritize your needs and satisfaction. Our customer support
              team is dedicated to assisting you throughout your journey,
              addressing any inquiries or concerns you may have. Your experience
              with Solarvipani.com is our top priority.
            </p>
          </h5>
        </div>
        <div className="about-sub-title">
          <h5>How It Works:</h5>
        </div>
        <div className="about-description">
          <h5 className="mb-3">
            <span>1. Identify your requirement:</span>
            <p className="ms-3">
              Using recent electricity bills and expected increase in
              electricity consumptions, estimate size of solar system that needs
              to be installed at your place.
            </p>
          </h5>
          <p className="mb-4 ms-3">
            Not sure of the required size? <br /> Check Recommendations using
            <NavLink to={"/solar"} className={"ms-1"}>
              Solar System Calculator
            </NavLink>
          </p>
          <h5 className="mb-3">
            <span>2. Request quotation:</span>
            <p className="ms-3">
              Once the approximate requirement is known, request quotation by
              filling the form
            </p>
          </h5>
          <h5 className="mb-3">
            <span>3. Get Quotes:</span>
            <p className="ms-3">
              Receive quotations for your requirements from 3 to 5 verified
              solar companies working in your area.
            </p>
          </h5>
          <h5 className="mb-3">
            <span>4. Consultation:</span>
            <p className="ms-3">
              Directly connect with the Solar EPC companies of your choice based
              on received quotation and make consultations for your specific
              requirements.
            </p>
          </h5>
          <h5>
            <span>5. Make Informed Decisions:</span>
            <p className="ms-3">
              Armed with credible information about companies, service
              expectation and rates, make confident decisions to install solar
              systems at your Residence/Business.
            </p>
          </h5>
        </div>
        <div className="about-sub-title">
          <h5>Join Us in Building a Sustainable Future:</h5>
        </div>
        <div className="about-description about-last-spacing">
          <p className="mb-3">
            At Solarvipani.com, we believe in the power of solar energy to
            transform lives and contribute to a sustainable future. Join us on
            this journey towards clean and renewable energy by making informed
            decisions with confidence. Discover the perfect Solar EPC partner
            for your residential/business project today.
          </p>
          <p className="mb-3">
            Welcome to a brighter, greener future with Solarvipani.com!
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
