import React from "react";

const ThankYou = () => {
    // const userId = "";
    // const clickTime = new Date(Date.now()).toLocaleString();
    // console.log({ userId, page: "ThankYou", clickTime });
    return (
        <>
            <div className="container">
                <div className="about-title mt-5">
                    <h4>
                        Congratulations on taking a step towards Solar Rooftop!
                    </h4>
                </div>
                <div className="about-description">
                    <p>
                        Your request has been received, and we&#39;re excited to help you!.
                    </p>
                </div>
                <div className="about-sub-title mt-5 mb-4">
                    <h5>What to Expect Next:</h5>
                </div>
                <div className="about-description">
                    <p className="mb-3">
                        1. A representative will reach out to understand your specific requirements in next 2 business days.
                    </p>
                    <p className="mb-3">
                        2. The representative will connect with verified solar installation companies in your area if you need a personalized Solar Quotation.
                    </p>
                    {/* <p className="mb-3">
                        3. Provide you top 3 personalized solar quotations
                    </p> */}
                </div>
                <div className="about-sub-title mt-5 mb-4">
                    <h5>Key Benefits of Solar Rooftop:</h5>
                </div>
                <div className="about-description">
                    <p>- Lower electricity bills</p>
                    <p>- Government Subsidy</p>
                    <p>- Easy financing options at attractive interest rates</p>
                    <p>- Sustainable and Environmentally friendly energy source</p>
                </div>
            </div>
        </>
    );
};

export default ThankYou;
