import React, { useEffect, useState } from "react";
import StateValue from "../data/state3.json";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Directory = () => {

    const navigation = useNavigate();

    const [state, setState] = useState({
        selectedState: "",
        selectedDistrict: "",
        selectedCity: "",
    });

    const [citys, setcitys] = useState([]);

    const [csvData, setCsvData] = useState([]);
    const stateNames = Object.keys(StateValue);

    const [pagination, setPagination] = useState({
        total: 0,
        lastPage: 0,
        prevPage: null,
        nextPage: null,
        perPage: 9,
        currentPage: 1,
    });

    const handleRedirect = async (id) => {
        try {
            const response = await axios.get(
                `https://admin.solarvipani.com/business-details/${id}`
            );
            const data = response.data.data;
            navigation(`/solar-panel-installer/${id}`, { state: data });
        } catch (error) {
            console.error("API Error with ID:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "state") {
            setState((prevData) => ({
                ...prevData,
                selectedState: value,
                selectedDistrict: "",
                selectedCity: "",
            }));
        } else if (name === "district") {
            setState((prevData) => ({
                ...prevData,
                selectedDistrict: value,
                selectedCity: "",
            }));

        }
        else if (name === "city") {
            setState((prevData) => ({
                ...prevData,
                selectedCity: value,
            }));

            setPagination((prev) => ({
                ...prev,
                currentPage: 1,
            }));

            navigation(`/solar-panel-installer-directory/${value.toLowerCase()}`, {
                state: true,
            })
        }
    };

    const selectedStateData = state.selectedState
        ? StateValue[state.selectedState].data
        : [];

    const FetchCityData = async (district) => {
        try {
            const response = await axios.get(
                `https://admin.solarvipani.com/citys/${district}`
            );
            setcitys(response.data.city);

        } catch (error) {
            console.error("citys-API-Error++", error);
        }
    }

    useEffect(() => {
        FetchCityData(state?.selectedDistrict);
    }, [state?.selectedDistrict]);


    const fetchData = async (page) => {
        try {
            const response = await axios.get(
                // `https://admin.solarvipani.com/business-list-new/${state.selectedDistrict.toLowerCase()}?page=${page}&limit=${pagination.perPage}`
                `https://admin.solarvipani.com/business-list-city/${state.selectedCity.toLowerCase()}?page=${page}&limit=${pagination.perPage}`
            );
            setCsvData(response.data.data);

            const newPagination = response.data.pagination;
            setPagination((prev) => ({
                ...prev,
                total: newPagination.total || prev.total,
                lastPage: newPagination.lastPage || Math.ceil(prev.total / prev.perPage),
                prevPage: newPagination.prevPage,
                nextPage: newPagination.nextPage,
                perPage: newPagination.perPage,
                currentPage: newPagination.currentPage,
            }));

        } catch (error) {
            console.error("API Error:", error);
        }
    };

    useEffect(() => {
        if (pagination?.currentPage) {
            fetchData(pagination?.currentPage);
        }
    }, [state.selectedCity, pagination?.currentPage]);

    // Title City-Name 
    useEffect(() => {
        const city = state.selectedCity || "Your Area";
        document.title = `Solar System Installers in ${city} - Solar Vipani`;
    }, [state.selectedCity]);

    const Pagination = (pageNumber) => {
        setPagination((...pre) => ({
            ...pre,
            currentPage: pageNumber,
        }));
    }


    return (
        <div className="solar_calculator_section">
            <div className="container">
                <div className="top_text d-flex align-items-center justify-content-between flex-wrap">
                    <h1> Solar System Installers in {state.selectedCity || "Your Area"} </h1>
                    <NavLink to={"/business-form"} className="btn btn-primary">
                        Add Business
                    </NavLink>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="solar_calculator_left_side">
                            <form>
                                <div className="mb-4">
                                    <label htmlFor="state" className="form-label">
                                        State
                                    </label>
                                    <select
                                        className="form-select"
                                        id="state"
                                        name="state"
                                        onChange={handleChange}
                                        value={state.selectedState}
                                    >
                                        <option value={""}>Select</option>
                                        {stateNames.map((v, i) => (
                                            <option value={v} key={i}>
                                                {StateValue[v].name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {selectedStateData.length > 0 ? (
                                    <div className="mb-4">
                                        <label htmlFor="district" className="form-label">
                                            District
                                        </label>
                                        <select
                                            className="form-select"
                                            id="district"
                                            name="district"
                                            onChange={handleChange}
                                            value={state.selectedDistrict}
                                        >
                                            <option value={""}>Select</option>
                                            {selectedStateData.map((district, i) => (
                                                <option value={district} key={i}>
                                                    {district}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {state.selectedDistrict ? (
                                    <div className="mb-4">
                                        <label htmlFor="city" className="form-label">
                                            City/Town/Area
                                        </label>

                                        {

                                            <select
                                                className="form-select"
                                                id="city"
                                                name="city"
                                                value={state.selectedCity}
                                                onChange={handleChange}
                                            >
                                                <option value={""}>Select</option>
                                                {
                                                    citys.map((city, i) => (
                                                        <option value={city} key={i}>
                                                            {city}
                                                        </option>
                                                    ))
                                                }
                                            </select>

                                        }
                                    </div>
                                ) : (
                                    ""
                                )}
                            </form>
                        </div>
                    </div>

                    {csvData?.length > 0 ? (
                        <div className="col-12 mt-5">
                            <h4 className="search-result-title mb-4">Search results</h4>
                            <div className="solar_calculator_right_side">
                                {Array.isArray(csvData) && csvData.length > 0 ? (
                                    <div className="row">
                                        {
                                            csvData.map((v, i) => {
                                                return (
                                                    <div
                                                        className="col-lg-4 col-md-6 col-12 mb-3"
                                                        key={i}
                                                    >
                                                        <div className="card h-100">
                                                            <div className="card-body">
                                                                {(v?.note !== "Blank" && v?.note) && (
                                                                    <h5
                                                                        className="card-sub-title"
                                                                        onClick={() => handleRedirect(v?.id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {v?.note}
                                                                    </h5>
                                                                )}
                                                                <h5
                                                                    className="card-title"
                                                                    onClick={() => handleRedirect(v?.id)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    {v?.name}
                                                                </h5>
                                                                <div
                                                                    className="card-text d-flex mb-3"
                                                                    onClick={() => handleRedirect(v?.id)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <div className="me-2">
                                                                        <i className="fa-solid fa-phone"></i>
                                                                    </div>
                                                                    <div>{v?.phoneNumber}</div>
                                                                </div>
                                                                {v?.website ? (
                                                                    <div
                                                                        className="card-text d-flex mb-3"
                                                                        onClick={() => handleRedirect(v?.id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <div className="me-2">
                                                                            <i className="fa-solid fa-globe"></i>
                                                                        </div>
                                                                        <div>
                                                                            <a
                                                                                href={v?.website}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="ellipsis"
                                                                            >
                                                                                {v?.website.replace(
                                                                                    /^(https?:\/\/)?|\/$/g,
                                                                                    ""
                                                                                )}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <div
                                                                    className="card-text d-flex"
                                                                    onClick={() => handleRedirect(v?.id)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <div className="me-2">
                                                                        <i className="fa-solid fa-location-dot"></i>
                                                                    </div>
                                                                    <div>{v?.address ? v?.address : "----"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        <h1>Data not found</h1>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    {/* Pagination */}
                    {csvData?.length > 0 &&
                        <div className="paginate d-flex justify-content-center mt-5">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className={`page-item ${pagination?.currentPage === 1 ? "disabled" : ""}`}>
                                        <a className="page-link cursor-pointer" onClick={() => Pagination(pagination.currentPage - 1)} aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    {Array.from({ length: pagination.lastPage }, (_, index) => (
                                        <li
                                            key={index}
                                            className={`page-item ${pagination.currentPage === index + 1 ? "active" : ""}`}
                                        >
                                            <a
                                                className="page-link cursor-pointer"
                                                onClick={() => Pagination(index + 1)}
                                            >
                                                {index + 1}
                                            </a>
                                        </li>
                                    ))}
                                    <li className={`page-item ${pagination?.currentPage === pagination.lastPage ? "disabled" : ""}`}>
                                        <a className="page-link cursor-pointer" onClick={() => Pagination(pagination.currentPage + 1)} aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Directory;

